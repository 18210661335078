import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


const IconsGrid = ((props) => {

    const { allFile } = useStaticQuery(
        graphql`
            query IconsQuery {
                allFile(
                    filter: {
                        absolutePath: {
                            regex: "/(.*)/icons/(.*)/"
                        },
                        extension: {
                            eq: "png"
                        }
                    },
                    sort: {order: ASC, fields: absolutePath}
                )
                {
                    edges {
                        node {
                            id
                            name
                            relativePath
                            dir
                            publicURL
                            relativeDirectory
                            sourceInstanceName
                            base
                        }
                    }
                }
            }
        `
    )

    let relativeDirectory = '';

    switch (props.slug) {
        case 'diversity-avatars-icons':
            relativeDirectory = 'icons/avatars/essential-pack';
            break;
        case 'simple-line-icons':
            relativeDirectory = 'icons/simple-line/thumbnails';
            break;
        case 'dog-avatars':
            relativeDirectory = 'icons/dog-avatars/320px';
            break;
        case 'dog-activities-1':
            relativeDirectory = 'icons/dog-activities-01';
            break;
        case 'dog-activites-2':
            relativeDirectory = 'icons/dog-activities-02';
            break;
        case 'business-and-finance-icons':
            relativeDirectory = 'icons/business-and-finance';
            break;
        case '15-bike-icons':
            relativeDirectory = 'icons/bikes-01';
            break;
        case 'bike-icons':
            relativeDirectory = 'icons/bikes-02';
            break;
        case 'wedding-icons':
            relativeDirectory = 'icons/weddings';
            break;
        case '650-free-vector-icons':
            relativeDirectory = 'icons/free/iconified/64px';
            break;
        case 'free-vector-illustrations':
            relativeDirectory = 'icons/free/miscellaneous';
            break;
    }

    return (
        <Row key={'icons-grid'} className={'icons-grid mt-4'}>
            {allFile.edges.map((file) => {
                const icon = file.node;
                if (icon.relativeDirectory !== relativeDirectory) return;
                return(
                    <Col xs={3} sm={2}>
                        <Image src={icon.publicURL} alt={icon.name} className={props.className} fluid />
                    </Col>
                )
            })}
        </Row>
    )
})

export default IconsGrid;
